.spacer {
  flex: 1;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.content {
  flex: 1;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
}
