@import 'ui/styles/view.module.scss';

.bubble {
  display: inline-flex;
  margin-right: 5px;
}

.mainInfo {
  margin-bottom: 40px;
}
