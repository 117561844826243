@import 'ui/styles/index.scss';

.field {
  width: 100%;
  margin-top: 1em;
}

.actions {
  display: flex;
  flex-flow: column;
  text-align: center;
  margin-top: 1em;
  align-items: center;
}

.logos {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-bottom: 74px;
  margin-top: 114px;
}

.logosFirstRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding-bottom: 20px;
}

.casualForm {
  margin: auto;
  width: 90%;
}

.title {
  text-align: center;
}

@include mobile {
  .logo {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 34px;
    margin-top: 20%;
  }

  .actions {
    text-align: center;
    margin-top: 2em;
  }
}

.forgotPassword {
  margin-top: 1em;
}

.infoMessage {
  padding: 8px;
  font-weight: 700;
}
