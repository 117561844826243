@import 'ui/styles/index.scss';

.actions {
  text-align: center;
  margin-top: 64px;
}

.errors {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #fa1e1e;
  text-align: center;
  margin-top: 1em;
}

.inputText {
  max-width: $desktop-width;
  margin-top: 1em;
}

.inputCheckbox {
  max-width: $desktop-width;
  margin-top: 0.5em;
}

.container {
  padding: 48px 64px;
  width: 100%;
  height: auto;
}

.canAddRow {
  display: flex;
}

.addButton {
  outline: none;
  width: 38px;
  height: 38px;
  margin-top: 1em;
  font-weight: 700;
  font-size: 16px;
  border: none;
  background: none;
  cursor: pointer;
}

.multipleOnLine,
.multipleOnLineNoMobile {
  display: flex;
  align-items: center;
  max-width: $desktop-width;
}

.iconText {
  padding: 4px;
}

.elementOfLine {
  flex: 1;
}

.marginRight {
  margin-right: 16px;
}

@include mobile {
  .container {
    padding: 8px 16px;
  }

  .multipleOnLine {
    display: block;
  }

  .paddingRight {
    margin-right: 0px;
    width: 100%;
  }
}

.marginTop {
  margin-top: 48px;
}
