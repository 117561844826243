@import 'ui/styles/index.scss';

.container {
  @extend %form-element;
}

.select {
  @extend %form-element;
  font-size: 16px;
  line-height: 22px;
  color: #333333;
}
