.localeButton {
  background: none;
  border: none;
  cursor: pointer;
}

.activeLocaleButton {
  font-weight: bold;
  color: #0c2396;
}
