@import 'ui/styles/list.module.scss';

.select {
  padding-right: 16px;
}

.separator {
  margin: 20px 0;
}

.actionContainer {
  margin: 10px 0;
}
