@import 'ui/styles/index.scss';

.markerContainer {
  width: 200px;
  padding-top: 8px;
}

.name {
  border-radius: 4px;
  color: white;
  padding: 15px 12px;
  font-weight: 800;
  font-size: 11px;
  line-height: 15px;
  margin-bottom: 18px;
  &.withAlert {
    background-color: #ff3333;
  }
  &.withoutAlert {
    background-color: #00cd83;
  }
}

.rowIconText {
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 8px;
}

.details {
  margin-left: 4px;
  font-size: 10px;
  line-height: 14px;
  &.withAlert {
    color: #ff3333;
    font-weight: 800;
  }
}

.bubble {
  position: absolute;
  right: 0;
}

.button {
  width: 100%;
  padding: 4px 0;
}
