@import 'ui/styles/index.scss';

.logo {
  width: 300px;
  display: block;
}

.AIlogo {
  width: 200px;
  display: block;
  position: fixed;
  bottom: 20px;
  right: 20px;
}
