@import 'ui/styles/index.scss';

.container {
  display: flex;
  align-items: center;
}

.checkbox {
  height: 16px;
  width: 16px;
  border-radius: 2px;
  cursor: pointer;
  display: block;
  margin-right: 16px;
}

.label {
  cursor: pointer;
}
