@import 'ui/styles/index.scss';

// placeholder selector
%_text {
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.05em;
  color: #000000;
  white-space: pre;
}

.container {
  padding: 48px 64px;
  width: 100%;
  height: auto;
}

.panelContainer {
  display: flex;
  width: 100%;
}

.leftPanel {
  width: 30%;
  padding: 16px;
}

.rightPanel {
  width: 100%;
  height: 600px;
  overflow: auto;
}

.modifyButton {
  margin: 8px;
}

.spacer {
  flex: 1;
}

.rowIconText {
  display: flex;
  padding: 8px;
  align-items: center;
}

.details {
  @extend %_text;
  display: flex;
  align-items: center;
  padding: 8px;
}

.list {
  @extend %_text;
}

.link {
  text-decoration: none;
}

.mainButton {
  display: flex;

  @include mobile {
    display: none;
  }
}

@include under-desktop {
  .panelContainer {
    width: 100%;
    display: block;
  }

  .leftPanel {
    width: 100%;
    padding: 16px;
    display: flex;
  }

  .rightPanel {
    width: 100%;
    padding: 16px;
    height: 712px;
    overflow: auto;
  }
  .container {
    padding: 32px;
    width: 100%;
  }
}

@include mobile {
  .leftPanel {
    display: block;
  }

  .container {
    width: 100%;
  }

  .rightPanel {
    overflow: inherit;
  }
}

.separator {
  margin: 20px 0;
}
