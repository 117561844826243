@import 'ui/styles/index.scss';

.text {
  font-size: 20px;
}

.icon {
  padding: 4px 6px 4px 6px;
}

.marker {
  width: 9px;
  height: 9px;
  border-radius: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.containerLine {
  display: flex;
  align-items: center;
}

.okAlerts {
  background-color: #00cd83;
}

.criticalAlerts {
  background-color: #ff3333;
}

.majorAlerts {
  background-color: #ffa500;
}

.minorAlerts {
  background-color: #ffde33;
}

.slash {
  padding: 0 6px;
}
