@import '../../styles/variables.scss';

.selectedDay {
  background-color: $color-primary;
  &:hover {
    background-color: $color-primary;
  }
}

.input {
  width: 100%;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
}
