@import 'ui/styles/index.scss';

.row {
  cursor: pointer;
  margin-bottom: 16px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 16px;
}

.rowElement {
  overflow: hidden;
  flex: 1;
  display: flex;
  &:last-child {
    justify-content: flex-end;
  }
}

.iconAndTextContainer {
  display: flex;
}

.textRow {
  margin-left: 16px;
  font-weight: 700;
  white-space: pre;
  text-overflow: ellipsis;
}

.subTextRow {
  margin-left: 16px;

  font-weight: 300;
  font-size: 14px;
  white-space: pre;
}

.bubble {
  align-self: flex-start;
}
