@import 'ui/styles/add-form.module.scss';

.passwordInfo {
  font-size: 13px;
}

.passwordInfoHighlighted {
  font-size: 13px;
  color: #fa1e1e;
}

.warning {
  font-size: 13px;
}
