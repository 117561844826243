@import 'ui/styles/index.scss';

.container {
  @extend %form-element;
  padding: 15px;
  color: #333333;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.textInput {
  margin-left: 5px;
  color: #333333;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  border: none;
  outline: none;
  width: 85%;
}

.iconContainer {
  vertical-align: middle;
}
