@import 'ui/styles/index.scss';

.page {
  background: white;
}
.header {
  height: 50px;
  width: 100%;
}
.content {
  width: 100%;
  position: relative;
}

.footer {
  display: none;
  @include mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    bottom: 0;
    padding: 16px;
    width: 100%;
    z-index: 3;
    background-color: white;
  }
}
