@import 'ui/styles/index.scss';

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.15);
  z-index: 4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal {
  outline: none;
  position: relative;
  background-color: white;
  display: inline-block;
  padding: 64px 32px;
  border-radius: 5px;
  max-height: 90vh;
  overflow-y: auto;

  @include mobile {
    width: 90%;
  }
}

.modal:after {
  content: "";
  display: block;
  height: 64px;
  width: 100%;
}

.cross {
  position: absolute;
  right: 13px;
  top: 13px;
  cursor: pointer;
  color: $color-jumbo;

  @include mobile {
    font-size: 32px;
  }
}
