@import 'ui/styles/index.scss';

.listRow {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.listRowElement {
  overflow: hidden;
  flex: 1;
  display: flex;
  &:last-child {
    justify-content: flex-end;
  }
}

.iconAndTextContainer {
  display: flex;
}

.textListRow {
  margin-left: 16px;
  font-weight: 800;
  white-space: pre;
  text-overflow: ellipsis;
}

.bubble {
  align-self: flex-start;
}
