@import 'ui/styles/add-form.module.scss';

.mainContainer {
  display: flex;
}

.formContainer {
  @include mobile {
    width: 100%;
  }
}

.mapContainer {
  flex: 1;
  margin-left: 16px;
  @include mobile {
    display: none;
  }
}

.fullWidth {
  width: 100%;
}
