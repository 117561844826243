@import 'ui/styles/index.scss';

.firstRow {
    display: flex;
    flex-direction: row;
    padding-bottom: 2px;
}
  
.supervisionHeaderFont {
    font-family: Arial, Helvetica, sans-serif;
    color: 	#001a45;
    margin-top: 10px;
    font-size: smaller;
}

.supervisionFont {
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
    color: 	#001a45;
    margin-top: 10px;
    font-size: larger;
}

.logos {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-bottom: 74px;
    margin-top: 114px;
}

@include mobile {
    .logos {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        margin-bottom: 30px;
        margin-top: 30px;
    }
}
  
.logosFirstRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding-bottom: 20px;
}