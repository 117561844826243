.message {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
}

.error {
  color: #fa1e1e;
}

.success {
  color: #1efa1e;
}
