@import 'ui/styles/index.scss';

.form {
  display: flex;
  @include mobile {
    flex-direction: column;
  }
}

.select {
  @include mobile {
    margin-bottom: 50px;
  }
}

.site {
  margin-left: 8px;
  margin-right: 8px;
  @include mobile {
    margin-left: 0;
    margin-right: 0;
  }
}
