@import 'ui/styles/list.module.scss';

.select {
  padding-left: 16px;
}

.separator {
  margin: 20px 0;
}

.searchText {
  margin-right: 20px;
}
