@import 'ui/styles/index.scss';

$spaceBetweenHeaderLinks: 24px;

.userMenu {
  display: flex;
  @include under-desktop {
    display: none;
  }

  & > section {
    display: flex;
    align-items: center;
    padding: 0 $spaceBetweenHeaderLinks;
    @include under-desktop {
      border: none;
    }
  }

  & > :last-child {
    padding: 0 12px;
    @include under-desktop {
      margin-bottom: $spaceBetweenHeaderLinks;
    }
  }

  .emailLink {
    margin-left: 8px;
    text-decoration: none;
    color: black;
  }

  .phone {
    margin-left: 8px;
  }
}

.headerContainer {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.15);
  width: 100%;
  display: flex;
}

.logoContainer {
  padding: 8px;
  cursor: pointer;
}

.menuIcon {
  display: none;
}

.mainMenu {
  display: flex;
  flex: 1;
}

.headerLinkContainerSelected {
  border-bottom: 5px solid $color-torea-bay;
}

.headerLinkContainer {
  display: flex;
  padding: 0 $spaceBetweenHeaderLinks;
  position: relative;
}

.headerLinkContainer:hover {
  background-color: #ececec;
  cursor: pointer;
}

.dropdownContent {
  background-color: #f1f1f1;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 3;
  position: absolute;
  width: 100%;
  top: 50px;
  left: 0px;
  display: none;
}

.headerLinkContainer:hover > .dropdownContent {
  display: block;
}

.subRouteLink {
  display: flex;
  align-items: center;
  padding: 8px;
  background-color: white;
  width: 100%;
}

.subRouteLink:hover {
  background-color: #ececec;
}

.headerLink {
  align-self: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}

.logout {
  margin: 0;
  padding: 0 12px;
  background: none;
  text-decoration: underline;
  -webkit-appearance: none;
  border: none;
  font-size: 16px;
  line-height: 22px;

  cursor: pointer;
}

.mobileMenuOpened {
  display: inline;
}

.login {
  margin-left: 5px;
}

// Mobile classes
@include under-desktop {
  .headerContainer {
    display: block;
    position: relative;
    border-bottom: none;
    background-color: white;
    z-index: 3;
  }

  .menuIcon {
    display: flex;
    position: absolute;
    top: 4px;
    right: 16px;
    cursor: pointer;
  }

  .headerLinkContainer {
    display: none;
  }

  .mobileHeaderLink {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .headerLink {
    padding: $spaceBetweenHeaderLinks 0;
  }

  .dropdownContent {
    background-color: #f1f1f1;
    box-shadow: none;
    width: 100%;
    display: contents;
  }

  .headerLinkContainer:hover > .dropdownContent {
    display: contents;
  }

  .headerLinkContainer:hover {
    background-color: white;
    cursor: pointer;
  }
}

.spacer {
  flex: 1;
}
