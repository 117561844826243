@import 'ui/styles/list.module.scss';

.head {
  display: flex;
}

.headIcon {
  margin: 10px 0;
  display: none;
  @include mobile {
    display: flex;
    align-items: center;
  }
}

.desktopOnly {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include tablet {
    flex-direction: column;
  }
  @include mobile {
    display: none;
  }
}

.searchButton {
  margin-top: 18px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 40px;
}

.deviceStatus {
  padding: 8px 16px 8px 0px;
  @include mobile {
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  }
  & + .deviceStatus {
    padding-left: 16px;
    border-left: solid 1px rgba(0, 0, 0, 0.1);
    @include mobile {
      padding-left: 0;
      border-left: none;
    }
  }
}

.buttonMobile {
  margin: 4px 8px;
}

.separator {
  margin: 10px 0;
}

.weather {
  display: flex;
  right: 0;
}
