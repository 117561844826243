@import 'ui/styles/index.scss';

.mainContainer {
  display: flex;
  height: 60vh;
  @include mobile {
    margin: 0 -15px;
  }
}

.mapContainer {
  flex: 1;
}

.listContainer {
  flex: 1;
  margin-left: 16px;
  overflow-y: scroll;
  @include mobile {
    display: none;
  }
}
