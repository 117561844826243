@import 'ui/styles/index.scss';

.container {
  text-align: center;
}

.loader {
  display: inline-block;
}
.loader:after {
  content: ' ';
  display: block;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  border: 5px solid $color-primary;
  border-color: $color-primary transparent $color-primary transparent;
  animation: loader 1.2s linear infinite;
}
@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
