@import 'ui/styles/index.scss';

.button {
  @extend %form-element;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  padding: 15px;
  &:disabled {
    opacity: 0.3;
    cursor: default;
  }
}

$colorRoles: (
  danger: #ff0000,
  primary: #0c2396,
  secondary: #77777a,
);
$darkenHover: 10%;
$darkenActive: 30%;

.fill {
  border: none;
  color: white;
  @each $role, $color in $colorRoles {
    &.#{$role} {
      background-color: $color;
      &:hover {
        background-color: darken($color, $darkenHover);
      }
      &:active {
        background-color: darken($color, $darkenActive);
      }
    }
  }
}

.stroke {
  background-color: white;
  border: 1px solid;
  @each $role, $color in $colorRoles {
    &.#{$role} {
      border-color: $color;
      color: $color;
      &:hover {
        border-color: darken($color, $darkenHover);
        color: darken($color, $darkenHover);
      }
      &:active {
        border-color: darken($color, $darkenActive);
        color: darken($color, $darkenActive);
      }
    }
  }
}

.iconContainer {
  margin-right: 10px;
}

.text {
  font-size: 16px;
}
