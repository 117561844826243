@import 'ui/styles/index.scss';

.paginator {
  margin-top: 32px;
  display: flex;
  width: 100%;
  align-items: center;
}

.arrowBox {
  width: 90px;
  height: 40px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  margin: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.spacer {
  flex: 1;
}
