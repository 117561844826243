@import 'ui/styles/view.module.scss';
@import 'ui/form/inputText/index.module.scss';

.alertDialog {
  display: flex;
  justify-content: space-between;
  border: 1px solid #ff0000;
  align-items: center;
  margin: 0 auto 8px auto;
  padding: 8px;
  min-height: 70px;
  .alertText {
    font-weight: bold;
    font-size: 12px;
    color: #ff0000;
  }
}

.alertAcknowlegedDialog {
  border-color: #77777a;
  .alertText {
    color: #77777a;
  }
}

.grafanaFrame {
  width: 100%;
  height: 800px;
  padding-bottom: 32px;
  border: none;
}

.grafanaTimeSelection {
  padding: 16px 0;
  & > *:not(:last-child) {
    margin-right: 10px;
  }
}

.headerContainer {
  position: relative;
}

.mainButton {
  position: absolute;
  right: 0;
  bottom: 10px;
  @include tablet {
    display: flex;
    position: relative;
    justify-content: flex-end;
  }
}

.panelAndMap {
  display: flex;
  justify-content: space-between;
  @include mobile {
    flex-direction: column;
  }
}

.informationPanel {
  display: flex;
  justify-content: start;
  flex-direction: column;
  flex: 1;
}

.map {
  flex: 1;
}

.details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .title {
    color: #0c2396;
    font-weight: bold;
  }
}

.artifact {
  margin-top: 4px;
}
