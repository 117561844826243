@import 'ui/styles/index.scss';

.container {
  width: 100%;
}

.line {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.sectionTitle {
  font-weight: 600;
  font-size: 32px;
  line-height: 44px;
  color: #333333;
  margin: 50px 0 20px 0;
  @include mobile {
    font-size: 24px;
    margin: 20px 0;
  }
}

.separator {
  margin: 20px 0;
}
