@import-normalize;
@import url('https://fonts.googleapis.com/css?family=Nunito&display=swap');

body {
  height: 100%;
  font-family: 'Nunito', sans-serif;
  margin: 0;
  padding: 0;
}

button {
  font-family: 'Nunito', sans-serif;
}

* {
  box-sizing: border-box;
}
